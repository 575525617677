import { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "../styles/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import { convertToRaw, EditorState } from "draft-js";

const toolbar = {
  options: ["inline", "list", "textAlign", "link", "image", "history"],
  inline: { options: ["bold", "italic", "underline"] },
  list: { options: ["unordered", "ordered"] },
  link: { options: ["link"] },
};

const WYSIWYG = ({ placeholder, setData, name }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    setData((pre) => ({
      ...pre,
      [name]: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    }));
  }, [editorState, name, setData]);

  return (
    <Editor
      editorState={editorState}
      placeholder={placeholder}
      toolbar={toolbar}
      onEditorStateChange={setEditorState}
    />
  );
};

export default WYSIWYG;
