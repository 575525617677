import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Date from "../../Components/Date";
import Header from "../../Components/Header";
import Select from "../../Components/Select";
import WYSIWYG from "./Components/WYSIWYG";
import "./styles/index.sass";
import Wilayas from "../AjouteOffre/Components/Wilayas.json";
import Departements from "../AjouteOffre/Components/Departements.json";
import Gouvernorats from "../AjouteOffre/Components/Gouvernorats.json";

const AjouterOffre = () => {
  let navigate = useNavigate();
  const apiURL = "https://api-rh.shiftin.co/offres/";
  const token = localStorage.getItem("token");
  const [load, setLoad] = useState(false);
  const [data, setData] = useState({
    jobTitle: "",
    publicationDate: "",
    expirationDate: "",
    nature: "",
    type_contrat: "",
    wilaya: "",
    email_destinataire: "",
    jobDescription: "",
    requiredProfile: "",
    autre: "",
  });

  const { id } = useParams();

  useEffect(() => {
    fetch(`${apiURL}${id}/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log({ data });
        setData(data);
        setLoad(true);
      });
  }, [token, id]);

  const handleInput = ({ target: { name, value } }) =>
    setData((pre) => ({ ...pre, [name]: value }));

  const EditOffre = (id, e) => {
    const updateOffre = {
      ...data,
      data: data,
    };
    e.preventDefault();
    fetch(`${apiURL}${id}/`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(updateOffre),
    })
      .then((response) => response.json())
      .then((data) => {
        setData(data);
      })
      .then(() => navigate("/GestionDesOffres"));
  };

  useEffect(
    (id) => {
      console.log(data);
    },
    [data]
  );

  return (
    <>
      <Header />
      <h2>{(id ? "Modifier" : "Ajouter") + " une offre d'emploi"}</h2>
      <div className="container Ajouteoffre">
        <form>
          <div className="formEmploi editForm">
            <div className="col col-1">
              <input
                value={data.jobTitle}
                onChange={handleInput}
                name="jobTitle"
                type="text"
                placeholder="Intitulé du poste*"
              />
              <Date
                value={data.publicationDate}
                name="publicationDate"
                handleInputs={handleInput}
                placeholder={"Date de publication de l'offre*"}
              />
              <Date
                value={data.expirationDate}
                name="expirationDate"
                handleInputs={handleInput}
                placeholder={"Date d'expiration de l'offre*"}
              />
              <RadioInput
                name="nature"
                handleInput={handleInput}
                values={["Emploi", "Stage"]}
                title="Nature :"
                value={data.nature}
              />
              <RadioInput
                name="type_contrat"
                handleInput={handleInput}
                values={["Plein temps", "Mi-temps"]}
                title="Type de poste"
                value={data.type_contrat}
              />
              <Select
                placeholder={"Pays*"}
                name="pays"
                options={["Algérie", "France", "Tunisie"]}
                value={data.pays}
                handleInputs={handleInput}
              />
              <Select
                placeholder={"Wilaya*"}
                name="wilaya"
                options={
                  data.pays === "Algérie"
                    ? Wilayas
                    : data.pays === "France"
                    ? Departements
                    : data.pays === "Tunisie"
                    ? Gouvernorats
                    : []
                }
                value={data.wilaya}
                handleInputs={handleInput}
              />
              <input
                value={data.email_destinataire}
                onChange={handleInput}
                name="email_destinataire"
                type="email"
                placeholder="E-mail du destinataire*"
              />
            </div>
            <div className="col col-2">
              <h4 className="fieldTitle">Description du poste</h4>
              <WYSIWYG
                load={load}
                name="jobDescription"
                placeholder={"Description du poste*"}
                setData={setData}
                value={data.jobDescription}
              />
              <h4 className="fieldTitle">Profil recherché</h4>
              <WYSIWYG
                name="requiredProfile"
                setData={setData}
                load={load}
                value={data.requiredProfile}
                placeholder={"Profil recherché*"}
              />
              <h4 className="fieldTitle">Autres</h4>
              <WYSIWYG
                setData={setData}
                value={data.autre}
                name="autre"
                load={load}
                placeholder={"Autres..."}
              />
            </div>
          </div>
          <button onClick={(e) => EditOffre(id, e)} className="downloadBtn">
            {id ? "Modifier" : "Ajouter"} l'offre d'emploi
          </button>
        </form>
      </div>
    </>
  );
};

export default AjouterOffre;

const RadioInput = ({ title, values, value, name, handleInput }) => {
  return (
    <div className="radioInput">
      <span>{title}</span>
      {values.map((e) => (
        <label key={e}>
          <input
            hidden
            onChange={handleInput}
            checked={e === value}
            value={e}
            name={name}
            type="radio"
          />
          <span className="yellowCircle"></span>
          {" " + e}
        </label>
      ))}
    </div>
  );
};
