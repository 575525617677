import React, { useEffect } from "react";
import "../../../styles/deletePopup.sass";
import { NavLink, useParams } from "react-router-dom";

const DeletePopup0 = () => {
  const { id } = useParams();
  console.log(id);

  useEffect(() => {
    document.documentElement.style.overflowY = "hidden";
    return () => (document.documentElement.style.overflowY = "unset");
  }, []);

  return (
    <div className="deleteWindow popup">
      <h3>Supprimer l'offre d'emploi ?</h3>
      <p>
        Etes-vous sûr de vouloir supprimer l'offre d'emploi de "
        {`emploi id:${id}`}"?
        <br />
        Cette action est definitive. Les candidatures reliées à l'offre d'emploi
        restent valables.
      </p>
      <div className="actions">
        <NavLink to={"../"} className="cancel">
          Annuler
        </NavLink>
        <button className="delete">
          <i className="fa-solid fa-trash-can"></i> Supprimer
        </button>
      </div>
    </div>
  );
};

export default DeletePopup0;
