const Pagination = ({
  currentPage,
  candPerPage,
  totalCand,
  paginate,
  setCandPerPage,
}) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalCand / candPerPage); i++) {
    pageNumbers.push(i);
  }

  // Get last item of Pages numbers
  const lastItem = pageNumbers[pageNumbers.length - 1];

  const handleRowPerPage = (e) => {
    setCandPerPage(e.target.value);
  };

  return (
    <div className="browseTable">
      <div className="limit">
        <label>
          Nomber de candidatures a afficher :
          <select
            name="perPage"
            id="selectRow"
            onChange={handleRowPerPage}
            value={candPerPage}
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </label>
      </div>

      <div className="pageNumber">
        <ul>
          {totalCand ? (
            <button
              disabled={currentPage === 1}
              onClick={(e) => paginate(e, currentPage - 1)}
              className="pre empty "
            >
              Précédent
            </button>
          ) : null}
          {pageNumbers.map((number) => (
            <li key={number}>
              <button
                onClick={(e) => paginate(e, number)}
                href="!#"
                className={`${currentPage === number ? "act" : ""}`}
              >
                {number}
              </button>
            </li>
          ))}
        </ul>
        {totalCand ? (
          <button
            disabled={currentPage >= lastItem}
            onClick={(e) => paginate(e, currentPage + 1)}
            className="next "
          >
            Suivant
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default Pagination;
