import { faEye, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, Field, Form, Formik, useFormikContext } from "formik";
import { Dispatch, SetStateAction, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import {
  LabelPropsI,
  PasswordPropsI,
  loginValidation,
  initialValueRegis,
  registeringValidation,
  SetSubmitting,
} from "../Components";

const submit = async (
  login: boolean,
  setMsg: Dispatch<SetStateAction<string>>,
  values: { email: string; password: string },
  p: SetSubmitting,
  navigate: NavigateFunction
) => {
  console.log(p);
  const { setSubmitting, resetForm } = p;

  console.log("Submitting...", values);
  const res = await (
    await fetch(
      `https://api-rh.shiftin.co/accounts/${
        login ? "/authenticate/" : ""
      }`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            !login ? localStorage.getItem("token") : ""
          }`,
        },
        method: "POST",
        body: JSON.stringify(
          login ? { email: values.email, password: values.password } : values
        ),
      }
    )
  ).json();
  console.log("pure", { res });

  res.message && setMsg(res.message);
  if (res.jwtToken) {
    localStorage.setItem("token", res.jwtToken);
    localStorage.setItem("user", JSON.stringify(res));
    navigate("/");
  } else if (res.email) {
    setMsg("Compte créé avec succès !");
    resetForm();
    setTimeout(() => {
      setMsg("");
    }, 4000);
  }

  console.log({ res });
  setSubmitting(false);
};

export const Basic = ({ login }: { login: boolean }) => {
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();
  return (
    <Formik
      onSubmit={(v, s) => submit(login, setMsg, v, s, navigate)}
      validationSchema={login ? loginValidation : registeringValidation}
      initialValues={initialValueRegis}
    >
      {({ isSubmitting }) => (
        <Form autoComplete="off">
          {login ? <Login /> : <Registering />}

          <span className="">{msg}</span>
          <button type="submit" disabled={isSubmitting}>
            {isSubmitting && <FontAwesomeIcon icon={faSpinner} />}
            {login ? "Se connecter" : "Créer le compte"}
          </button>
        </Form>
      )}
    </Formik>
  );
};

// SCREENS

const Login = () => (
  <>
    <Label
      title="Email address"
      input={{
        type: "email",
        name: "email",
        placeholder: "Entrer votre E-mail",
      }}
    />
    <Password
      title="Le mot de passe"
      input={{ placeholder: "Entrer votre mot de passe", name: "password" }}
    />
  </>
);

const Registering = () => (
  <>
    <div className="row">
      <Label
        title="Prénom"
        input={{
          placeholder: "Entrer votre prénom",
          type: "text",
          name: "firstName",
        }}
      />
      <Label
        title="Nom"
        input={{
          placeholder: "Entrer votre Nom",
          type: "text",
          name: "lastName",
        }}
      />
    </div>

    <Label
      title="Email address"
      input={{
        placeholder: "Entrer votre e-mail",
        type: "text",
        name: "email",
      }}
    />

    <div className="row" style={{ marginBottom: 10 }}>
      <Select
        v="title"
        name="title"
        options={["M.", "Mme.", "Mlle."]}
        placeholder="Titre"
        title="Titre"
      />
      <Select
        v="role"
        name="role"
        options={["Admin", "User"]}
        placeholder="rôle"
        title="rôle"
      />
    </div>

    <Password
      title="Mot de passe"
      input={{ placeholder: "Entrer mot de passe", name: "password" }}
    />
    <Password
      title="Confirmez le mot de passe"
      input={{
        placeholder: "Confirmez le mot de passe",
        name: "confirmPassword",
      }}
    />
  </>
);

// PARTS

const Label = ({ title, input }: LabelPropsI) => (
  <label className="mb-4 block w-full">
    <span className="labelTitle">{title}</span>
    <Field {...input} />
    <ErrorMessage name={input.name} component="div" className="errorMsg" />
  </label>
);

const Password = ({ input, title }: PasswordPropsI) => {
  const [show, setshow] = useState(false);
  return (
    <label className="mb-4 block">
      <span className="labelTitle">{title}</span>
      <div className="password">
        <FontAwesomeIcon
          icon={faEye}
          style={{ color: show ? "#00358c" : "#ddd" }}
          onClick={() => setshow((e) => !e)}
        />
        <Field {...input} className="" type={show ? "text" : "password"} />
      </div>
      <ErrorMessage name={input.name} component="div" className="errorMsg" />
    </label>
  );
};

interface props {
  title: string;
  placeholder: string;
  name: string;
  options: string[];
  v: "role" | "title";
}

const Select = ({ title, placeholder, name, options = [], v }: props) => {
  const { values } = useFormikContext<{ role: string; title: string }>();
  const value = values[v];
  return (
    <div className="selectInput fields">
      {title && (
        <label className="labelTitle" htmlFor={name}>
          {title}
        </label>
      )}
      <div className="selectContainer">
        <i className="fa-solid fa-angle-down"></i>
        <Field as="select" name={name}>
          <option selected value="" disabled>
            {placeholder}
          </option>
          {options.map((e, i) => (
            <option key={e + i} value={e}>
              {e}
            </option>
          ))}
        </Field>
        <span>{value && value !== "--none--" ? value : placeholder}</span>
      </div>

      <ErrorMessage name={name} component="div" className="errorMsg" />
    </div>
  );
};
