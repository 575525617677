import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Header from "../../Components/Header";
import Controller from "./Components/Controller";
import "./styles/index.sass";

const EditerCandidature = () => {
  let navigate = useNavigate();
  const [candidat, setCandidat] = useState([]);
  const [candData, setcandData] = useState({});

  const { id } = useParams();
  const apiURL = "https://api-rh.shiftin.co/candidatures/";
  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchData = () => {
      fetch(`${apiURL}${id}/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          console.log(data);
          setCandidat(data);
        });
    };
    fetchData();
  }, [token, id]);

  const updateCandApi = (id) => {
    const statusUpdate = {
      ...candidat,
      status: candData.status,
      mention: candData.mention,
    };
    fetch(`${apiURL}${id}/`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(statusUpdate),
    })
      .then((response) => {
        return response.json();
      })
      .then(navigate("/"));
  };

  return (
    <div>
      <Header />
      <h2>Modifier la candidature</h2>
      <div className="editing">
        <Controller candData={candData} setcandData={setcandData} />
      </div>
      <div className="preview0 container">
        <h3>Fiche du candidat</h3>
        <div className="grid wrapper">
          <div className="grid">
          <div>
            <h4 className="fieldTitle">Identité</h4>
          
            <div className="title">
              <b>Nom :</b> {candidat.nom}
            </div>
            <div className="title">
                  <b>E-mail :</b> {candidat.email}
                </div>
                <div className="title">
                  <b>Téléphone :</b> {candidat.phone}
                </div>
         
          </div>
          <div>
            <h4 className="fieldTitle">Candidature</h4>
            <div className="title">
              <b>Type :</b> {candidat.type}
            </div>
            <div className="title">
              <b>Poste :</b> {candidat.poste}
            </div>

            <a
              className="cv-btn-link"
              href={`${candidat.cv}`}
              target="_blank"
              rel="noreferrer"
            >
              <div className="downloadCv">
                <i className="fa-solid fa-file-arrow-down"></i>
                <div className="txt">
                  <b>Télécharger le CV </b>
                  <span>{candidat.cv?.split("/").pop()}</span>
                </div>
              </div>
            </a>
          </div>
         
            </div>
            <div className="second-row">

            <div>
            <h4 className="fieldTitle">Message</h4>
            
            <div className="title">
               {candidat.message}
            </div>
            
         
              
            </div>
          </div>
        </div>
        <div className="btn-update">
          <button
            className="downloadBtn updateCandidat"
            onClick={() => updateCandApi(id)}
          >
            Sauvegarder les changements
          </button>
        </div>
      </div>
      <footer className="container">
        Données strictement confidentielles / &copy; Tous droits réservés - CM
        Consulting
      </footer>
    </div>
  );
};

export default EditerCandidature;
