import { useId, useRef } from "react";

const DatePicker = ({ value, title, placeholder, handleInputs, name, min,hideTitle }) => {
  const id = useId();
  const ele = useRef(null);
  const handleClick = () => {
    ele.current.showPicker();
  };
  return (
    <div className="fields">
      {title && <label style={{opacity:hideTitle?0:1}} htmlFor={id}>{title}</label>}
      <label onClick={handleClick} htmlFor={id} className="dateInput">
        <input
          ref={ele}
          value={value}
          onChange={handleInputs}
          type="date"
          min={min}
          name={name}
          id={id}
        />
        <span> {value ? value : placeholder} </span>
        <i className="fa-solid fa-calendar-day"></i>
      </label>
    </div>
  );
};

export default DatePicker;
