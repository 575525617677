import { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import "../styles/previewPopup.sass";

const PreviewPopupOffre = () => {
  const [offrePreview, setOffrePreview] = useState({});
  const { id } = useParams();
  const apiURL = "https://api-rh.shiftin.co/offres/";

  useEffect(() => {
    document.documentElement.style.overflowY = "hidden";
    return () => (document.documentElement.style.overflowY = "unset");
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");

    const fetchData = () => {
      fetch(`${apiURL}${id}/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          console.log(data);
          setOffrePreview(data);
        });
    };
    fetchData();
  }, [id]);

  if (!offrePreview.jobTitle) return;

  return (
    <div className="preview popup">
      <NavLink to="../">
        {" "}
        <i className="fa-solid fa-xmark"></i>
      </NavLink>
      <h3>
        Offre d'emploi <strong>{offrePreview.jobTitle}</strong>
      </h3>
      <div className="grid">
        <div>
          <h4 className="fieldTitle">Informations générales</h4>
          <div className="title">
            <b>Titre :</b> {offrePreview.jobTitle}
          </div>
          <div className="title">
            <b>Date de publication :</b> {offrePreview.publicationDate}
          </div>
          <div className="title">
            <b>Date d'expiration :</b> {offrePreview.expirationDate}
          </div>
          <div className="title">
            <b>E-mail du destinataire :</b> {offrePreview.email_destinataire}
          </div>
        </div>
        <div>
          <h4 className="fieldTitle">Caractéristiques de l'offre</h4>
          <div className="title">
            <b>Nature de l'offre :</b> {offrePreview.nature}
          </div>
          <div className="title">
            <b>Type du contrat :</b> {offrePreview.type_contrat}
          </div>
          <div className="title">
            <b>Localisation :</b> {offrePreview.wilaya}
          </div>
          <div className="title">
            <b>Status :</b> {offrePreview.status}
          </div>
        </div>
      </div>
      <div className="flex-container">
        <h4 className="fieldTitle">Description du poste</h4>
        <div className="flex">
          <div
            dangerouslySetInnerHTML={{ __html: offrePreview.jobDescription }}
          ></div>
        </div>
      </div>
      <div className="flex-container">
        <h4 className="fieldTitle">Profil recherché</h4>
        <div className="flex">
          <div
            dangerouslySetInnerHTML={{ __html: offrePreview.requiredProfile }}
          ></div>
        </div>
      </div>
      <div className="flex-container">
        <h4 className="fieldTitle">Autres Informations</h4>
        <div className="flex">
          <div dangerouslySetInnerHTML={{ __html: offrePreview.autre }}></div>
        </div>
      </div>
    </div>
  );
};

export default PreviewPopupOffre;
