import { useEffect, useState } from "react";
import "../../../styles/table.sass";
import DataTable, { createTheme } from "react-data-table-component";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { filterFun } from "../../../utils/table";
import { Link } from "react-router-dom";
import Pagination from "./Pagination";
import moment from "moment";
import toast, { Toaster } from "react-hot-toast";

const Table = ({ filter }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [candPerPage, setCandPerPage] = useState(5);

  const apiURL = "https://api-rh.shiftin.co/accounts/";
  const token = localStorage.getItem("token");
  useEffect(() => {
    setIsLoading(true);
    fetch(apiURL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((responseData) => {
        setResponse(responseData);
        setIsLoading(false);
      });
  }, [token]);

  // Get current accounts
  const indexLastCand = currentPage * candPerPage;
  const indexFirstCand = indexLastCand - candPerPage;

  const allValid = filterFun(filter, response);

  const toDisplay = allValid.slice(indexFirstCand, indexLastCand);

  // Change page
  const paginate = (e, pageNumber) => {
    e.preventDefault();
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [filter, candPerPage]);

  const columns = [
    {
      id: 1,
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
      reorder: true,
      center: true,
      allowOverflow: false,
      width: "100px",
    },
    {
      id: 2,
      name: "Désignation",
      selector: (row) => row.title,
      sortable: true,
      reorder: true,
      center: true,
      allowOverflow: false,
      width: "100px",
    },
    {
      id: 3,
      name: "Date de création",
      selector: (row) => moment(row.created).format("DD-MM-YYYY"),
      sortable: true,
      reorder: true,
      center: true,
      allowOverflow: false,
    },
    {
      id: 4,
      name: "Nom",
      selector: (row) => row.firstName,
      sortable: true,
      reorder: true,
      center: true,
      allowOverflow: true,
    },
    {
      id: 5,
      name: "Prénom",
      selector: (row) => row.lastName,
      sortable: true,
      reorder: true,
      center: true,
      allowOverflow: true,
    },
    {
      id: 6,
      name: "E-mail",
      selector: (row) => row.email,
      sortable: true,
      reorder: true,
      center: true,
      allowOverflow: true,
      className: "emailField",
      width: "250px",
    },
    {
      id: 7,
      name: "Rôle",
      selector: (row) => row.role,
      sortable: true,
      reorder: true,
      center: true,
      allowOverflow: true,
      width: "100px",
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="actions-btn">
          <Link to="#!" onClick={(e) => popDelete(row.id, e)}>
            <i className="fa-solid fa-trash-can"></i>
          </Link>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: "150px",
    },
  ];

  const deleteAccount = async (id, e) => {
    const notifySuccess = () =>
      toast.success("Le compte a bien été supprimé !");

    const notifyError = (msg) =>
      toast.error(`Une erreur est survenue : ${msg}`);

    e.preventDefault();

    const res = await fetch(`${apiURL}${id}/`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (res.status === 200) {
      setResponse(response.filter((e) => e.id !== id));
      notifySuccess();
    } else {
      const g = await res.json();
      console.log("Test error", g.message);
      notifyError(g.message);
    }
  };

  const popDelete = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div>
            <div className="deleteWindow">
              <h3>Supprimer le compte ?</h3>
              <p>
                Etes-vous sûr de vouloir supprimer ce compte ? Cette action est
                definitive.
              </p>
              <div className="actions">
                <a href="#!" className="cancel" onClick={onClose}>
                  Annuler
                </a>
                <a
                  href="!#"
                  className="delete"
                  onClick={(e) => {
                    deleteAccount(id, e);
                    onClose();
                  }}
                >
                  <i className="fa-solid fa-trash-can"></i>
                  Supprimer
                </a>
              </div>
            </div>
          </div>
        );
      },
    });
  };

  createTheme(
    "cmc",
    {
      background: {
        default: "#fff",
      },
      text: "#00358c",
    },
    "light"
  );

  return (
    <div className="tableContainer">
      <div className="mainTable">
        <Toaster />
        <DataTable
          theme="cmc"
          columns={columns}
          progressPending={isLoading}
          data={toDisplay}
          defaultSortFieldId={1}
          defaultSortAsc={true}
          noDataComponent="Aucun compte existant."
        />
        <Pagination
          candPerPage={candPerPage}
          totalCand={allValid.length}
          paginate={paginate}
          currentPage={currentPage}
          setCandPerPage={setCandPerPage}
        />
      </div>
    </div>
  );
};

export default Table;
