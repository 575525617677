import { useState, useEffect } from "react";
import Filter from "./Components/Filter";
import Header from "../../Components/Header";
import Table from "./Components/Table";
import "./styles/index.sass";
import { Outlet } from "react-router-dom";

const initial = { search: "", date_de_candidature: "" };
function GestionDesCandidatures() {
  const filter = useState(initial);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    console.log(selected);
  }, [selected]);

  return (
    <>
      <Header />
      <div className="container">
        <h2>Gestion des candidatures</h2>
        <Filter selected={selected} filter={filter} />
        <h3>Liste des candidatures</h3>
        <Table setSelected={setSelected} filter={filter[0]} />
        <footer>
          <span>
            Données strictement confidentielles / &copy; Tous droits réservés -
            SHIFTIN
          </span>
        </footer>
      </div>
      <Outlet />
    </>
  );
}

export default GestionDesCandidatures;
