import { useEffect } from "react";
import Select from "../../../Components/Select";
import { useParams } from "react-router-dom";

const Controller = ({ candData, setcandData }) => {
  const { id } = useParams();
  const apiURL = "https://api-rh.shiftin.co/candidatures/";
  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchData = () => {
      fetch(`${apiURL}${id}/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setcandData(data);
        });
    };
    fetchData();
  }, [token, id, setcandData]);

  const handleInput = ({ target: { name, value } }) => {
    setcandData((pre) => ({ ...pre, [name]: value }));
  };

  useEffect(() => {
    if (candData.status === "nouvelle" && candData.mention)
      setcandData((e) => ({
        ...e,
        status: "en-cours",
      }));
    console.log("Only checkbox changed", candData);
  }, [candData.mention, setcandData, candData]);

  return (
    <div className="container">
      <Select
        placeholder="Filtrer par Status"
        title="Status de la candidature :"
        handleInputs={handleInput}
        name="status"
        options={[
          "Nouvelle",
          "En cours",
          "Présélectionnée",
          "Validée",
          "Refusée",
        ]}
        values={[
          "nouvelle",
          "en-cours",
          "preselectionnee",
          "validee",
          "refusee",
        ]}
        value={candData.status}
      />
      <div className="radioLabels fields">
        <label className="title">Appréciation :</label>

        <div className="labelsContainer">
          <input
            checked={candData.mention === "Faible"}
            onChange={handleInput}
            type="radio"
            id="faible"
            name="mention"
            value="Faible"
          />
          <label
            style={{
              borderColor: "hsl(0, 100%, 71%)",
              "--bg": "hsl(0, 67%, 91%)",
            }}
            htmlFor="faible"
          >
            Faible
          </label>

          <input
            id="Bien"
            checked={candData.mention === "Bien"}
            onChange={handleInput}
            type="radio"
            name="mention"
            value="Bien"
          />
          <label
            style={{
              borderColor: "hsl(25, 74%, 54%)",
              "--bg": "hsl(25, 67%, 91%)",
            }}
            htmlFor="Bien"
          >
            Bien
          </label>

          <input
            id="Excellent"
            checked={candData.mention === "Excellent"}
            value="Excellent"
            onChange={handleInput}
            type="radio"
            name="mention"
          />
          <label
            style={{
              borderColor: "hsl(120, 66%, 61%)",
              "--bg": "hsl(120, 67%, 91%)",
            }}
            htmlFor="Excellent"
          >
            Excellent
          </label>
        </div>
      </div>
      <a
        className="downloadBtn"
        href={`${candData.cv}`}
        target="_blank"
        rel="noreferrer"
      >
        Télécharger le CV <i className="fa-solid fa-file-arrow-down"></i>
      </a>
    </div>
  );
};

export default Controller;
