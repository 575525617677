import * as Yup from "yup";

export const loginValidation = Yup.object({
  password: Yup.string()
    .min(6, "Doit contenir 6 caractères ou plus")
    .required("Obligatoire"),
  email: Yup.string().email("Adresse e-mail invalide").required("Obligatoire"),
});

export const registeringValidation = Yup.object({
  email: Yup.string().email("Adresse e-mail invalide").required("Obligatoire"),
  title: Yup.string().required("Obligatoire"),
  firstName: Yup.string()
    .min(3, "Doit contenir 3 caractères ou plus")
    .matches(/^([a-zA-Zéèà+ +A-Zéèà]*)$/, {
      message:
        "Le champ ne doit pas contenir un chiffre ou des caractères spéciaux.",
    })
    .required("Obligatoire"),
  lastName: Yup.string()
    .min(3, "Doit contenir 3 caractères ou plus")
    .matches(/^([a-zA-Zéèà+ +A-Zéèà]*)$/, {
      message:
        "Le champ ne doit pas contenir un chiffre ou des caractères spéciaux.",
    })
    .required("Obligatoire"),
  role: Yup.string().required("Obligatoire"),
  password: Yup.string()
    .min(6, "Doit contenir 6 caractères ou plus")
    .required("Obligatoire"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Obligatoire"),
});

// initial values

export const initialValueRegis = {
  title: "",
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  confirmPassword: "",
  role: "",
};
