import { NavLink, Outlet } from "react-router-dom";
import Header from "../../Components/Header";
import Table from "./Components/Table";
import "./styles/index.sass";

const GestionDesOffres = () => {
  return (
    <>
      <Header />
      <div className="GestionDesOffres container">
        <h2>Gestion des offres d'emploi</h2>
        <div className="row">
          <h3>Liste des offres d'emploi</h3>
          <NavLink to="/GestionDesOffres/Ajoute">
            Ajouter une offre d'emploi <i className="fa-solid fa-plus"></i>
          </NavLink>
        </div>
        <Table />
        <footer>
          <span>
            Données strictement confidentielles / &copy; Tous droits réservés -
            CM Consulting
          </span>
        </footer>
      </div>
      <Outlet />
    </>
  );
};

export default GestionDesOffres;
