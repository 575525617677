import { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "../styles/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import { convertToRaw, EditorState, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";

const WYSIWYG = ({ placeholder, setData, name, value, load }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    setData((pre) => ({ ...pre, [name]: toHtml(editorState) }));
  }, [editorState, name, setData]);

  useEffect(() => {
    if (load) setEditorState(ValidDraft(value));
    // eslint-disable-next-line
  }, [load]);

  return (
    <Editor
      editorState={editorState}
      placeholder={placeholder}
      value={value}
      toolbar={toolbar}
      onEditorStateChange={setEditorState}
    />
  );
};





export default WYSIWYG;

const toolbar = {
  options: ["inline", "list", "textAlign", "link", "image", "history"],
  inline: { options: ["bold", "italic", "underline"] },
  list: { options: ["unordered", "ordered"] },
  link: { options: ["link"] },
};
const toHtml = (draft) => draftToHtml(convertToRaw(draft.getCurrentContent()));
const ValidDraft = (html) =>
  EditorState.createWithContent(
    ContentState.createFromBlockArray(htmlToDraft(html).contentBlocks)
  );
