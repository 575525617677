import { Link } from "react-router-dom";
import "../styles/filter.sass";

const Filter = ({ filter: [data, setData] }) => {
  const handleInputs = ({ target: { name, value } }) => {
    setData((e) => ({ ...e, [name]: value }));
  };

  return (
    <div className="controler">
      <div className="actions">
        <div className="actions-section Actions-l">
          <div className="searchInput">
            <i className="fa-solid fa-magnifying-glass"></i>
            <input
              value={data.search}
              onChange={handleInputs}
              placeholder="Rechercher un compte..."
              type="search"
              name="search"
            />
          </div>
        </div>
        <div className="actions-section Actions-r">
          <Link to={"/Creer"}>
            <button>
              {" "}
              Ajouter un compte &nbsp; <i className="fa-solid fa-plus"></i>
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Filter;
