import { useState } from "react";
import Filter from "./Components/Filter";
import Header from "../../Components/Header";
import Table from "./Components/Table";
import "./styles/index.sass";
import { Outlet } from "react-router-dom";

const initial = { search: "" };
function GestionComptes() {
  const filter = useState(initial);

  return (
    <>
      <Header />
      <div className="container">
        <h2>Gestion des comptes</h2>
        <Filter filter={filter} />
        <h3>Liste des comptes</h3>
        <Table filter={filter[0]} />
        <footer>
          <span>
            Données strictement confidentielles / &copy; Tous droits réservés -
            CM Consulting
          </span>
        </footer>
      </div>
      <Outlet />
    </>
  );
}

export default GestionComptes;
