import "../styles/Select.sass";

const Select = ({
  title,
  placeholder,
  handleInputs,
  name,
  value,
  options = [],
  values = [],
}) => {
  const optionsToValue = options.map((e, i) => ({
    text: e,
    value: values[i] ? values[i] : e,
  }));

  return (
    <div className="selectInput fields">
      {title && <label htmlFor={name}>{title}</label>}
      <div className="selectContainer">
        <i className="fa-solid fa-angle-down"></i>
        <select
          // defaultValue={"#"}
          value={value}
          onChange={handleInputs}
          name={name}
        >
          <option disabled value="#">
            {value && value !== "--none--" ? "" : placeholder}
          </option>
          {optionsToValue.map((e, i) => (
            <option key={e.text + i} value={e.value}>
              {e.text}
            </option>
          ))}
        </select>
        <span>
          {value && value !== "--none--"
            ? optionsToValue.find((e) => e.value === value)?.text
            : placeholder}
        </span>
      </div>
    </div>
  );
};

export default Select;
