import { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import "../styles/previewPopup.sass";

const PreviewPopupCand = () => {
  const [candidatPreview, setCandidatPreview] = useState([]);
  const { id } = useParams();
  const apiURL = "https://api-rh.shiftin.co/candidatures/";
  const token = localStorage.getItem("token");

  useEffect(() => {
    document.documentElement.style.overflowY = "hidden";
    return () => (document.documentElement.style.overflowY = "unset");
  }, []);

  useEffect(() => {
    const fetchData = () => {
      fetch(`${apiURL}${id}/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          console.log(data);
          setCandidatPreview(data);
        });
    };
    fetchData();
  }, [token, id]);

  return (
    <div className="preview popup">
      <NavLink to="../">
        {" "}
        <i className="fa-solid fa-xmark"></i>
      </NavLink>
      <h3>Fiche du candidat</h3>
      <div className="grid">
        <div>
          <h4 className="fieldTitle">Identité</h4>
          <div className="title">
            <b>Nom :</b> {candidatPreview.nom}
          </div>
          <div>
            <div className="title">
              <b>E-mail :</b> {candidatPreview.email}
            </div>
            <div className="title">
              <b>Téléphone :</b> {candidatPreview.phone}
            </div>
        
          </div>
        </div>
        <div>
          <h4 className="fieldTitle">Candidature</h4>
          <div>
            <b>Type :</b> {candidatPreview.type}
          </div>
          <div>
            <b>Poste :</b> {candidatPreview.poste}
          </div>

          <a
            className="cv-btn-link"
            href={`${candidatPreview.cv}`}
            target="_blank"
            rel="noreferrer"
          >
            <div className="downloadCv">
              <i className="fa-solid fa-file-arrow-down"></i>
              <div className="txt">
                <b>Télécharger le CV </b>
                <span>{candidatPreview.cv?.split("/").pop()}</span>
              </div>
            </div>
          </a>
        </div>
      </div>
     
      <div className="flex-container">
        <h4 className="fieldTitle">Message</h4>
        <div className="flex">
        {candidatPreview.message}
          <div>
           
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewPopupCand;
