import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import AjouterOffre from "./pages/AjouteOffre";
import EditerCandidature from "./pages/EditerCandidature";
import EditerOffre from "./pages/EditerOffre";
import GestionDesCandidatures from "./pages/GestionDesCandidatures";
import GestionComptes from "./pages/GestionComptes";
import DeletePopup from "./pages/GestionDesCandidatures/Components/DeletePopup";
import DeleteAccountPopup from "./pages/GestionComptes/Components/DeleteAccountPopup";
import PreviewPopupCand from "./pages/GestionDesCandidatures/Components/PreviewPopup";
import PreviewPopupOffre from "./pages/GestionDesOffres/Components/PreviewPopup";
import GestionDesOffres from "./pages/GestionDesOffres";
import DeletePopup0 from "./pages/GestionDesOffres/Components/DeletePopup";
import LoginPage from "./pages/Login";
import Statistiques from "./pages/Statistiques";
import Corbeille from "./pages/Corbeille";
import EditerProfil from "./pages/EditerProfil";

export const App = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<ProtectedRoute />}>
        <Route path="/" element={<GestionDesCandidatures />}>
          <Route path="/delete/:id" element={<DeletePopup />} />
          <Route path="/preview/:id" element={<PreviewPopupCand />} />
        </Route>

        <Route
          path="/gestionDesCandidatures/EditerCandidature/:id"
          element={<EditerCandidature />}
        />

        <Route path="/Corbeille/" element={<Corbeille />}>
          <Route path="/Corbeille/preview/:id" element={<PreviewPopupCand />} />
        </Route>

        <Route path="/GestionDesOffres/Ajoute" element={<AjouterOffre />} />

        <Route
          path="/GestionDesOffres/EditerOffre/:id"
          element={<EditerOffre />}
        />

        <Route path="/GestionDesOffres" element={<GestionDesOffres />}>
          <Route
            path="/GestionDesOffres/preview/:id"
            element={<PreviewPopupOffre />}
          />
          <Route
            path="/GestionDesOffres/delete/:id"
            element={<DeletePopup0 />}
          />
        </Route>

        <Route path="/Statistiques" element={<Statistiques />} />
        <Route path="/Creer" element={<LoginPage login={false} />} />
        <Route path="/GestionComptes" element={<ProtectedAdmin />}>
          <Route
            path="/GestionComptes/delete/:id"
            element={<DeleteAccountPopup />}
          />
        </Route>
      </Route>

      <Route path="/login" element={<LoginRoute />}>
        <Route path="/login" element={<LoginPage login={true} />} />
      </Route>

      <Route path="/EditerProfil" element={<EditerProfil />} />
    </Routes>
  </BrowserRouter>
);

const ProtectedRoute = () => {
  const token = localStorage.getItem("token");
  return !token ? <Navigate to={"/login"} replace /> : <Outlet />;
};

const ProtectedAdmin = () => {
  const user = JSON.parse(localStorage.getItem("user")).role;
  return user !== "Admin" ? <Navigate to={"/"} replace /> : <GestionComptes />;
};

const LoginRoute = () => {
  const token = localStorage.getItem("token");
  return token ? <Navigate to={"/"} replace /> : <Outlet />;
};
