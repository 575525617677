import React, { useEffect } from "react";
import "../../../styles/deletePopup.sass";
import { NavLink, useParams } from "react-router-dom";

const DeletePopup = () => {
  const { id } = useParams();
  console.log(id);

  useEffect(() => {
    document.documentElement.style.overflowY = "hidden";
    return () => (document.documentElement.style.overflowY = "unset");
  }, []);

  return (
    <div className="deleteWindow popup">
      <h3>Supprimer la candidature ?</h3>
      <p>
        Etes-vous sûr de vouloir supprimer la candidature de{" "}
        <span className="name"></span>?<br />
        Cette action est definitive.
      </p>
      <div className="actions">
        <NavLink to={"../"} className="cancel">
          Annuler
        </NavLink>
        <button className="delete">
          <i className="fa-solid fa-trash-can"></i> Supprimer
        </button>
      </div>
    </div>
  );
};

export default DeletePopup;
